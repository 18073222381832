<template>
<!--  技能提升-->
  <div class="bgtop-mian">
    <!-- PC端 -->
    <div :class="screenWidth >= 992 ? 'bgmain-service' : 'bgmain-service '" class="hidden-sm-and-down">
      <div class="bg-dry">
        <div class="bgchina-text">{{$t('background.提示类型')}}</div>
        <div class="bgenglish-text">{{$t('background.PromptType')}}</div>
        <div class="bgcon">
          <div class="bgleft">
            <template v-if="language=='zh'">
              <img src="@/assets/image/bg1.png" alt="" />
            </template>
            <template v-else-if="language=='TW'">
              <img src="@/assets/image/bg1TW.png" alt="" />
            </template>
            <template v-else-if="language=='En'">
              <img src="@/assets/image/bg1.png" alt="" />
            </template>
            <template v-else>
              <img src="@/assets/image/bg1.png" alt="" />
            </template>
            <div class="jtright">
              <div @click="totutor(1)">
                {{$t('background.IELTS')}}
                <img src="@/assets/image/rjt.png" alt="" />
              </div>
              <div @click="totutor(2)">
                {{$t('background.TOEFL')}}
                <img src="@/assets/image/rjt.png" alt="" />
              </div>
              <div>
                <!-- <span @click="totutor(3)">{{$t('background.GRE')}}</span>/ -->
                <span @click="totutor(4)">{{$t('background.EMET')}}</span>
                <img src="@/assets/image/rjt.png" alt="" />
              </div>
            </div>
          </div>
          <div class="bgleft">
            <template v-if="language=='zh'">
              <img src="@/assets/image/bg2.png" alt="" />
            </template>
            <template v-else-if="language=='TW'">
              <img src="@/assets/image/bg2.png" alt="" />
            </template>
            <template v-else-if="language=='En'">
              <img src="@/assets/image/bg2.png" alt="" />
            </template>
            <template v-else>
              <img src="@/assets/image/bg2.png" alt="" />
            </template>
            <div class="jtright">
              <div @click="totutor(5)">
                {{$t('background.实习项目')}}
                <img src="@/assets/image/rjt.png" alt="" />
              </div>
              <div @click="totutor(6)">
                {{$t('background.科研项目')}}
                <img src="@/assets/image/rjt.png" alt="" />
              </div>
              <div @click="totutor(7)">
                {{$t('background.求职辅导')}}
                <img src="@/assets/image/rjt.png" alt="" />
              </div>
            </div>
          </div>
          <div class="bgleft">
            <template v-if="language=='zh'">
              <img src="@/assets/image/bg3.png" alt="" />
            </template>
            <template v-else-if="language=='TW'">
              <img src="@/assets/image/bg3TW.png" alt="" />
            </template>
            <template v-else-if="language=='En'">
              <img src="@/assets/image/bg3.png" alt="" />
            </template>
            <template v-else>
              <img src="@/assets/image/bg3.png" alt="" />
            </template>
            <div class="jtright">
              <div @click="totutor(8)">
                {{$t('background.论文辅导')}}
                <img src="@/assets/image/rjt.png" alt="" />
              </div>
              <div @click="totutor(9)">
                {{$t('background.课程辅导')}}
                <img src="@/assets/image/rjt.png" alt="" />
              </div>
              <div @click="totutor(10)">
                {{$t('background.留学面试辅导')}}
                <img src="@/assets/image/rjt.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 移动端 -->
    <div class="hidden-sm-and-up bgmain-service-sm">
      <div class="bg-dry">
        <div class="bgchina-text-sm">{{$t('background.提示类型')}}</div>
        <div class="bgenglish-text-sm">{{$t('background.PromptType')}}</div>
        <div class="bgcon-sm">
          <div class="bgleft-sm">
            <template v-if="language=='zh'">
              <img src="@/assets/image/bg1.png" alt="" />
            </template>
            <template v-else-if="language=='TW'">
              <img src="@/assets/image/bg1TW.png" alt="" />
            </template>
            <template v-else-if="language=='En'">
              <img src="@/assets/image/bg1.png" alt="" />
            </template>
            <template v-else>
              <img src="@/assets/image/bg1.png" alt="" />
            </template>
            <div class="jtright-sm">
              <div @click="totutor2(1)">
                {{$t('background.IELTS')}}
                <img src="@/assets/image/rjt.png" alt="" />
              </div>
              <div @click="totutor2(2)">
                {{$t('background.TOEFL')}}
                <img src="@/assets/image/rjt.png" alt="" />
              </div>
              <div>
                <span @click="totutor2(3)">{{$t('background.GRE')}}</span>/
                <span @click="totutor2(4)">{{$t('background.EMET')}}</span>
                <img src="@/assets/image/rjt.png" alt="" />
              </div>
            </div>
          </div>
          <div class="bgleft-sm">
            <template v-if="language=='zh'">
              <img src="@/assets/image/bg2.png" alt="" />
            </template>
            <template v-else-if="language=='TW'">
              <img src="@/assets/image/bg2.png" alt="" />
            </template>
            <template v-else-if="language=='En'">
              <img src="@/assets/image/bg2.png" alt="" />
            </template>
            <template v-else>
              <img src="@/assets/image/bg2.png" alt="" />
            </template>
            <div class="jtright-sm">
              <div @click="totutor2(5)">
                {{$t('background.实习项目')}}
                <img src="@/assets/image/rjt.png" alt="" />
              </div>
              <div @click="totutor2(6)">
                {{$t('background.科研项目')}}
                <img src="@/assets/image/rjt.png" alt="" />
              </div>
              <div @click="totutor2(7)">
                {{$t('background.求职辅导')}}
                <img src="@/assets/image/rjt.png" alt="" />
              </div>
            </div>
          </div>
          <div class="bgleft-sm">
            <template v-if="language=='zh'">
              <img src="@/assets/image/bg3.png" alt="" />
            </template>
            <template v-else-if="language=='TW'">
              <img src="@/assets/image/bg3TW.png" alt="" />
            </template>
            <template v-else-if="language=='En'">
              <img src="@/assets/image/bg3.png" alt="" />
            </template>
            <template v-else>
              <img src="@/assets/image/bg3.png" alt="" />
            </template>
            <div class="jtright-sm">
              <div @click="totutor2(8)">
                {{$t('background.论文辅导')}}
                <img src="@/assets/image/rjt.png" alt="" />
              </div>
              <div @click="totutor2(9)">
                {{$t('background.课程辅导')}}
                <img src="@/assets/image/rjt.png" alt="" />
              </div>
              <div @click="totutor2(10)">
                {{$t('background.留学面试辅导')}}
                <img src="@/assets/image/rjt.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-for="(item,index) in backurllist" :key='index' @click="tojump(item.jumpUrl)">
      <img
        :src="baseUrl+item.imageUrl"
        alt=""
        class="banner-imagelist"
    />
    </div>
    <Mydialog ref="icon" @sumbit='sumbit'></Mydialog>
    <Myicon @iconchange='iconchange'></Myicon>
  </div>
</template>

<script>
  import "element-ui/lib/theme-chalk/display.css";
  import Mydialog from "@/components/dialog"
  import Myicon from '@/components/mainicon'
  import {getJianzhiOne,addMessageRecord} from '@/api/index'
  export default {
    components: {
      Mydialog,
      Myicon
    },
    data() {
      return {
        language:'zh',
        screenWidth: document.body.clientWidth,
        screenHeight: document.body.clientHeight,
        backurllist:[],
        baseUrl:"",
        url: "https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fhbimg.b0.upaiyun.com%2Fe82575e1bc472edaeb120bb8976165ff7e8c71dd6cdd5-HVrxdx_fw658&refer=http%3A%2F%2Fhbimg.b0.upaiyun.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1654938807&t=c4ca465f95bca8716738d4c2c26a441e",
      };
    },
    mounted() {
      const that = this;
      window.onresize = () => {
        return (() => {
          window.screenWidth = document.documentElement.clientWidth; //实时宽度
          window.screenHeight = document.documentElement.clientHeight; //实时高度
          console.log(this.screenWidth, this.screenHeight);
          that.screenWidth = window.screenWidth;
          that.screenHeight = window.screenHeight;
          if (document.body.clientWidth <= 768) {
                 location.reload();
        }
        })();
      };
      that._getJianzhiOne()
      that.baseUrl=that.$store.state.baseUrl
      that.language=localStorage.getItem('language')
    },
    methods: {
    //在线咨询（背景提升）
    sumbit(e){
        e.category=2
      addMessageRecord(e).then(()=>{
        this.$message.success(this.$t('admission.已提交'))
      })
    },
      tojump(jumpurl){
       window.location.href = jumpurl
      },
      _getJianzhiOne(){
        let obj={
          category:2
        }
        getJianzhiOne(obj).then(res=>{
          console.log(res)
          this.backurllist=res.data
        })
      },
    iconchange(){
      this.$refs.icon.dialogVisible=true
    },
      totutor(e) {
        if (e == 1) {
          //Ielts
          this.$router.push({
            name: "Ielts",
          });
        }
        if (e == 2) {
          //托福
          this.$router.push({
            name: "toefl",
          });
        }
        if (e == 3) {
          //GRE
          this.$router.push({
            name: "GRE",
          });
        }
        if (e == 4) {
          //GMAT
          this.$router.push({
            name: "GMAT",
          });
        }
        if (e == 5) {
          //实习项目
          this.$router.push({
            name: "Internship-program",
          });
        }
        if (e == 6) {
          //科研项目
          this.$router.push({
            name: "ScientificResearch",
          });
        }
        if (e == 7) {
          //求职辅导
          this.$router.push({
            name: "Coach",
          });
        }
        if (e == 8) {
          //论文辅导
          this.$router.push({
            name: "paper",
          });
        }
        if (e == 9) {
          //课程辅导
          this.$router.push({
            name: "tutor",
          });
        }
        if (e == 10) {
          //留学面试辅导
          this.$router.push({
            name: "Study-abroad",
          });
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  //  @media screen and (max-width: 992px){
  //       .bgmain-service{
  //           display: none;
  //       }
  //   }
  .mrgin-bot30 {
    margin-bottom: 30px;
  }

  .bgtop-mian {
    width: 100vw;
    background: #f7f7ff;
  }

  .bgmain-service {
    width: 1176PX;
    margin: 0 auto;
    padding-bottom: 50px;
  }

  .bgmain-service-sm {
    width: 335PX;
    margin: 0 auto;
    padding-bottom: 50px;
  }

  .bgmain-service > div,
  .bgmain-service-sm > div {
    position: relative;
    top: 0;
  }

  .bgchina-text {
    font-size: 32PX;
    font-weight: 600;
  }

  .bgenglish-text {
    font-size: 28PX;
    color: #999999;
    margin-bottom: 20px;
  }

  .bg-dry {
    padding: 83px 0 70px 0;
    text-align: left;
  }

  .bgcon {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .bgleft {
    position: relative;
    width: 320px;
    height: 240px;
  }

  .bgleft img {
    width: 320px;
    height: 240px;
  }

  .jtright {
    position: absolute;
    top: 80px;
    color: #333;
  }

  .jtright img {
    width: 24px;
    height: 24px;
    vertical-align: -6px;
  }

  .jtright > div {
    height: 50px;
    line-height: 50px;
    margin-left: 20px;
    font-size: 17px;
  }

  // 移动端
  .bgchina-text-sm {
    font-size: 18PX;
    font-weight: 600;
  }

  .bgenglish-text-sm {
    font-size: 16PX;
    color: #999999;
    margin-bottom: 20px;
  }
  
  .bgcon-sm {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }
   .bgleft-sm {
    position: relative;
     width: 335PX;
     height: 229PX;
     margin-bottom: 20PX!important;
  }

  .bgleft-sm img {
    width: 335PX;
    height: 229PX;
    margin-bottom: 20PX!important;
  }

  .jtright-sm {
    position: absolute;
    top: 80PX;
    color: #333;
  }

  .jtright-sm img {
    width: 24PX;
    height: 24PX;
    vertical-align: -25PX;
    
  }


  .jtright-sm > div {
    height: 50PX;
    line-height: 50PX;
    margin-left: 20PX;
    font-size: 18PX;
  }
  .banner-imagelist{
    width:100vw;
    object-fit: cover;
  }
</style>
